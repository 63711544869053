import analytics from '@capturi/analytics'
import { useAudioContext } from '@capturi/audio'
import { useCurrentUser } from '@capturi/core'
import {
  List,
  ListItem,
  ListItemMenu,
  MenuItem,
  MenuList,
  Spinner,
} from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  BoxProps,
  Flex,
  MenuDivider,
  Text,
  TooltipProps,
  Tooltip as _Tooltip,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { LibraryAnalyticsEvent, LibraryFolder } from 'features/library'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { MdDelete, MdDeleteSweep, MdEdit, MdPlaylistPlay } from 'react-icons/md'

import useCancelSnippetDeletionModal from '../hooks/useCancelSnippetDeletionModal'
import { LibraryFolderSnippetsDeleteModal } from './LibraryFolderSnippetsDeleteModals'

type Props = {
  folders: LibraryFolder[]
  isLoading: boolean
  onSelect: (folder?: LibraryFolder) => void
  onEdit: (folder: LibraryFolder) => void
  onDelete: (folder: LibraryFolder) => void
  selectedFolderUid?: string
} & Omit<BoxProps, 'onSelect'>

const Tooltip: React.FC<TooltipProps> = (props) => {
  if (!props.label) {
    return props.children as React.ReactElement
  }
  return (
    <_Tooltip
      label={props.label}
      placement="auto-start"
      openDelay={300}
      {...props}
    >
      {props.children}
    </_Tooltip>
  )
}

const LibraryFolders: React.FC<Props> = ({
  folders = [],
  isLoading = false,
  onSelect,
  onEdit,
  onDelete,
  selectedFolderUid,
  ...boxProps
}) => {
  if (isEmpty(folders) && !isLoading) {
    return (
      <Text color="textMuted" textAlign="center" my={8}>
        <Trans>
          Playlists help you organize your audio clips. Add a playlist by
          clicking the button above.
        </Trans>
      </Text>
    )
  }
  if (isLoading) {
    return <Spinner display="block" m="0 auto" />
  }

  return (
    <List {...boxProps}>
      {folders.map((folder) => (
        <Folder
          key={folder.uid}
          {...folder}
          isSelected={selectedFolderUid === folder.uid}
          onSelect={() => onSelect(folder)}
          onEdit={() => onEdit(folder)}
          onDelete={() => onDelete(folder)}
        />
      ))}
    </List>
  )
}

type FolderProps = LibraryFolder & {
  onSelect: () => void
  onEdit: () => void
  onDelete: () => void
  isSelected: boolean
}

function Folder({
  uid,
  name,
  description,
  onSelect,
  onEdit,
  onDelete,
  isSelected,
}: FolderProps): React.ReactElement {
  const currentUser = useCurrentUser()
  const { isPlaying, stop } = useAudioContext()
  const [showFolderSnippetDeleteModal] = useModal(
    LibraryFolderSnippetsDeleteModal,
  )

  const snippetDeletionModal = useCancelSnippetDeletionModal(uid)

  const handleFolderSnippetDeleteModal: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    if (isPlaying) stop()
    showFolderSnippetDeleteModal({
      next: snippetDeletionModal,
    })
  }

  return (
    <ListItem
      key={uid}
      button
      dense
      defaultHideSecondaryAction
      px={1}
      onClick={() => {
        analytics.event(LibraryAnalyticsEvent.ChangeFocusPlaylistLibrary)
        onSelect()
      }}
    >
      <Flex
        fontWeight={isSelected ? 'medium' : 'normal'}
        cursor="pointer"
        color={isSelected ? 'brand.primary' : 'inherit'}
      >
        <Box as={MdPlaylistPlay} boxSize="20px" flexShrink={0} />
        <Tooltip key={uid} label={description}>
          <Text ml="3" noOfLines={1} wordBreak="break-all">
            {name}
          </Text>
        </Tooltip>
      </Flex>

      {currentUser.permissions.editLibrary && (
        <ListItemMenu placement={'auto-start'}>
          <MenuList>
            <MenuItem onClick={onEdit}>
              <MdEdit />
              <Text ml="2">
                <Trans>Edit</Trans>
              </Text>
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={onDelete}>
              <MdDelete />
              <Text ml="2">
                <Trans>Delete</Trans>
              </Text>
            </MenuItem>
            <MenuItem onClick={handleFolderSnippetDeleteModal}>
              <MdDeleteSweep />
              <Text ml="2">
                <Trans>Delete all content</Trans>
              </Text>
            </MenuItem>
          </MenuList>
        </ListItemMenu>
      )}
    </ListItem>
  )
}

export default LibraryFolders
