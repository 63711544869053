import { useOrganization } from '@capturi/stores'
import { useMemo } from 'react'
import { SWRConfiguration, SWRResponse } from 'swr'

import { useAPI } from './api'
import { LibraryFolder, LibraryFolderResponseModel } from './types'

function useLibraryFolders(swrConfig: SWRConfiguration = {}): {
  folders: LibraryFolder[]
  isLoading: boolean
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  mutate: SWRResponse<LibraryFolderResponseModel, any>['mutate']
} {
  const { conversationLanguage } = useOrganization()
  const compare = Intl.Collator(conversationLanguage).compare
  const { data, error, mutate } = useAPI<LibraryFolderResponseModel>(
    (api) => api.getFolders(),
    {
      suspense: true,
      focusThrottleInterval: 60000,
      ...swrConfig,
    },
  )

  return useMemo(() => {
    return {
      folders: ([] as LibraryFolder[])
        .concat(data?.folders ?? [])
        .sort((a, b) => compare(a.name, b.name)),
      isLoading: !(data || error),
      mutate,
    }
  }, [compare, data, error, mutate])
}

export default useLibraryFolders
