import { useAudioContext, useAudioShortcut } from '@capturi/audio'
import {
  ContentPlaceholder,
  List,
  SectionHeading,
  Spinner,
  useToast,
} from '@capturi/ui-components'
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import {
  LibraryAnalyticsEvent,
  LibraryItem,
  UseAudioSnippetsSortCriteria,
  ViewFolderPermissions,
  logEvent,
  api as snippetApi,
  useSnippetCRUD as useAudioSnippetCRUD,
  useAudioSnippets,
  useLibraryFolder,
} from 'features/library'
import React, { useEffect, useState } from 'react'
import { MdDateRange, MdSortByAlpha, MdSwapVert } from 'react-icons/md'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { downloadBlob } from '@capturi/request'
import { useNotificationsContext } from 'features/notifications'
import noop from 'lodash/noop'
import conversationRoutes from 'pages/Conversations/routes'
import Snippet from './Snippet'

const LibraryFolderContents: React.FC<{ uid?: string }> = ({ uid }) => {
  const navigate = useNavigate()
  const { markAudioSnippetNotificationsAsRead } = useNotificationsContext()
  const [searchParams] = useSearchParams()
  const toast = useToast()

  const { isPlaying, pause, playbackContext, setPlaybackContext } =
    useAudioContext()
  useAudioShortcut()
  const snippetUid = searchParams.get('snippet')
  const [currentlyFocusedItemUid, setFocusedItemUid] = useState<string | null>(
    snippetUid,
  )

  const { folder } = useLibraryFolder(uid)
  const [sortCriteria, setSortCriteria] =
    useState<UseAudioSnippetsSortCriteria>('creationDate')

  const {
    snippets,
    isLoading: isLoadingSnippets,
    mutate,
  } = useAudioSnippets(uid, sortCriteria)

  useEffect(() => {
    return setPlaybackContext(null)
  }, [setPlaybackContext])

  const { deleteSnippet, editSnippet } = useAudioSnippetCRUD({
    onSnippetDeleted: (snippet) => {
      if (isPlaying && playbackContext?.conversationUid === snippet.uid) pause()
      mutate(
        (data) =>
          data && {
            audioSnippets: data.audioSnippets.filter(
              (x) => x.uid !== snippet.uid,
            ),
          },
      )
    },
    onSnippetUpdated: (snippet) =>
      mutate(
        (data) =>
          data && {
            audioSnippets: data.audioSnippets.map((x) =>
              x.uid === snippet.uid ? snippet : x,
            ),
          },
      ),
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  React.useEffect(() => {
    if (snippets.length === 0) {
      return
    }

    function markNotificationsAsRead(): void {
      if (snippets.length > 0) {
        const uids = snippets.map((x) => x.uid)
        markAudioSnippetNotificationsAsRead(uids)
      }
    }

    const timer = setTimeout(markNotificationsAsRead, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [snippets])

  const handlePlay = (uid: string, snippet: LibraryItem): void => {
    setFocusedItemUid(uid)
    logEvent(LibraryAnalyticsEvent.PlaySnippetFromLibrary, {
      uid,
      date: snippet.conversationDateTime.toUTCString(),
    })
  }

  const handleDownload = async (uid: string, name: string): Promise<void> => {
    logEvent(LibraryAnalyticsEvent.DownloadSnippet)
    try {
      toast({
        title: t`Fetching snippet`,
        status: 'info',
      })

      const [snippetUrl] = snippetApi().getSnippetAudio(uid)

      await downloadBlob(snippetUrl, `${name}.wav`)
      toast({
        title: t`Snippet downloaded`,
        status: 'success',
      })
    } catch (_error) {
      toast({
        title: t`Could not download snippet`,
        status: 'error',
      })
    }
  }

  return (
    <Box mt={8}>
      <Flex wrap="wrap" mb={4}>
        <Box>
          <SectionHeading fontSize="2xl" mb={0}>
            {folder?.name}
          </SectionHeading>
          {folder?.description != null && (
            <Text color="gray.600" my={1}>
              {folder.description}
            </Text>
          )}
        </Box>
        <VStack alignItems="flex-end" ml="auto" pl={4}>
          <ViewFolderPermissions permissions={folder?.permissions} />
          {snippets.length > 0 && (
            <Menu>
              <MenuButton
                as={Button}
                h="25px"
                iconSpacing={1}
                leftIcon={<MdSwapVert />}
                px={2}
                size="xs"
                textTransform="uppercase"
                variant="ghost"
              >
                <Trans>Sort</Trans>
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem
                    fontWeight={
                      sortCriteria === 'alphabetically' ? 'medium' : 'normal'
                    }
                    icon={<MdSortByAlpha />}
                    onClick={() => {
                      setSortCriteria('alphabetically')
                    }}
                  >
                    <Trans>Alphabetically</Trans>
                  </MenuItem>
                  <MenuItem
                    fontWeight={
                      sortCriteria === 'conversationDate' ? 'medium' : 'normal'
                    }
                    icon={<MdDateRange />}
                    onClick={() => {
                      setSortCriteria('conversationDate')
                    }}
                  >
                    <Trans>Conversation date</Trans>
                  </MenuItem>
                  <MenuItem
                    fontWeight={
                      sortCriteria === 'creationDate' ? 'medium' : 'normal'
                    }
                    icon={<MdDateRange />}
                    onClick={() => {
                      setSortCriteria('creationDate')
                    }}
                  >
                    <Trans>Creation date</Trans>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          )}
        </VStack>
      </Flex>
      {isLoadingSnippets ? (
        <Spinner display="block" m="10vh auto" />
      ) : snippets.length === 0 ? (
        <ContentPlaceholder.Container mt="10vh">
          <ContentPlaceholder.Image as={Icon_EmptyState} />
          <ContentPlaceholder.Heading>
            <Trans>The playlist is empty</Trans>
          </ContentPlaceholder.Heading>
          <ContentPlaceholder.Body>
            <Trans>Add audio clips when listening to conversations</Trans>
          </ContentPlaceholder.Body>
        </ContentPlaceholder.Container>
      ) : (
        <List>
          {snippets.map((x) => (
            <Snippet
              key={x.uid}
              {...x}
              isFocused={currentlyFocusedItemUid === x.uid}
              onPause={noop}
              onPlay={() => handlePlay(x.uid, x)}
              onGoToConversation={() => {
                logEvent(LibraryAnalyticsEvent.GoToSnippetConversation)
                navigate({
                  pathname: conversationRoutes.conversation(x.conversationUid),
                  search: new URLSearchParams({
                    time: String(x.from),
                  }).toString(),
                })
              }}
              onDelete={() => deleteSnippet(x)}
              onEdit={() => editSnippet(x)}
              onDownload={() => handleDownload(x.uid, x.title)}
            />
          ))}
        </List>
      )}
    </Box>
  )
}

export default LibraryFolderContents
