import {
  Button,
  ContentPlaceholder,
  PageHeading,
  Spinner,
} from '@capturi/ui-components'
import { Box, Flex, Grid } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import {
  LibraryFolder,
  LibraryItem,
  cacheUpdaters,
  folderUtils,
  mutateLibraryFolder,
  useAPI,
  useFolderCRUD,
  useLibraryFolders,
} from 'features/library'
import React, { useEffect } from 'react'
import { MdBookmark, MdPlaylistAdd } from 'react-icons/md'
import {
  matchPath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { AudioProvider } from '@capturi/audio'
import { useCurrentUser } from '@capturi/core'
import { usePageTitle } from '@capturi/react-utils'
import LibraryFolderContents from './components/LibraryFolderContents'
import LibraryFolders from './components/LibraryFolders'
import libraryRoutes from './routes'

function useSnippetFolderRedirect(
  snippetUid?: string | null,
  folderUid?: string | null,
): void {
  const navigate = useNavigate()
  // Fetch snippet provided in query params
  const { data: snippetData } = useAPI<LibraryItem>(
    (api) => (snippetUid != null ? api.getSnippet(snippetUid) : null),
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  )

  React.useEffect(() => {
    if (snippetUid != null && snippetData != null) {
      if (snippetData.folderUid !== folderUid) {
        // Reroute to correct snippet folder
        navigate({
          pathname: libraryRoutes.folder(snippetData.folderUid),
          search: new URLSearchParams({
            snippet: snippetData.uid,
          }).toString(),
        })
      }
    }
  }, [navigate, snippetData, snippetUid, folderUid])
}

function LibraryPage(): React.ReactElement {
  usePageTitle(t`Library`)
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const match = matchPath('playlists/:uid', `/${params['*']}`)
  const folderUid = match?.params.uid
  const snippetUid = searchParams.get('snippet')

  const currentUser = useCurrentUser()

  // Redirect to correct folder if needed
  useSnippetFolderRedirect(snippetUid, folderUid)
  // Fetch folders
  const { folders, isLoading: isLoadingFolders, mutate } = useLibraryFolders()

  // Redirect to first folder, if no folder is selected
  useEffect(() => {
    if (folders.length > 0 && !snippetUid && !folderUid)
      navigate(libraryRoutes.folder(folders[0].uid), { replace: true })
  }, [folderUid, folders, navigate, snippetUid])

  const setCurrentFolder = (folder?: LibraryFolder): void => {
    navigate(libraryRoutes.folder(folder?.uid))
  }

  const folderCRUDIntents = useFolderCRUD({
    onFolderCreated: async (newFolder) => {
      await mutate((data) => cacheUpdaters.addFolder(data, newFolder))
      setCurrentFolder(newFolder)
    },
    onFolderDeleted: async (deletedFolder) => {
      await mutate((data) =>
        cacheUpdaters.deleteFolder(data, deletedFolder.uid),
      )
      if (folderUid === deletedFolder.uid) {
        const folderIdx = folders.findIndex((x) => x.uid === folderUid)
        const filteredFolders = folders.filter(
          (x) => x.uid !== deletedFolder.uid,
        )
        // Try to select other folder with same list index.
        let newIdx: number | undefined = folderIdx
        if (filteredFolders.length === 0) {
          newIdx = undefined
        }
        // If last item in list was deleted then select last item in filtered list
        if (folderIdx === folders.length - 1) {
          newIdx = filteredFolders.length - 1
        }
        setCurrentFolder(
          newIdx === undefined ? undefined : filteredFolders[newIdx],
        )
      }
    },
    onFolderUpdated: async (updatedFolder) => {
      const userAccessRevoked = !folderUtils.hasAccess(
        updatedFolder.permissions,
        currentUser,
      )
      await mutate((data) => {
        if (userAccessRevoked) {
          return cacheUpdaters.deleteFolder(data, updatedFolder.uid)
        }
        return cacheUpdaters.replaceFolder(data, updatedFolder)
      })
      if (folderUid === updatedFolder.uid) {
        if (userAccessRevoked) {
          setCurrentFolder(folders[0])
        } else {
          mutateLibraryFolder(updatedFolder.uid, updatedFolder)
        }
      }
    },
  })

  if (folders.length === 0) {
    return (
      <>
        <PageHeading>
          <Trans>Library</Trans>
        </PageHeading>
        <ContentPlaceholder.Container mt={20}>
          <ContentPlaceholder.Image as={Icon_EmptyState} />
          <ContentPlaceholder.Heading>
            <Trans>It&apos;s a bit empty here!</Trans>
          </ContentPlaceholder.Heading>
          <ContentPlaceholder.Body>
            <Trans>
              Playlists help you organize your audio clips and use them as
              references for later use
            </Trans>
          </ContentPlaceholder.Body>
          {currentUser.permissions.editLibrary && (
            <ContentPlaceholder.Footer>
              <Button
                primary
                onClick={folderCRUDIntents.createFolder}
                leftIcon={<MdBookmark />}
              >
                <Trans>Create playlist</Trans>
              </Button>
            </ContentPlaceholder.Footer>
          )}
        </ContentPlaceholder.Container>
      </>
    )
  }

  return (
    <Box>
      <Grid templateColumns={{ md: '1fr auto' }} alignItems="center" mb={0}>
        <PageHeading>
          <Trans>Library</Trans>
        </PageHeading>
        {currentUser.permissions.editLibrary && (
          <Flex justify="center">
            <Button
              primary
              size="sm"
              onClick={folderCRUDIntents.createFolder}
              leftIcon={<MdPlaylistAdd />}
            >
              <Trans>Create playlist</Trans>
            </Button>
          </Flex>
        )}
      </Grid>
      <Grid
        templateColumns={{
          md: '1fr 1fr',
          lg: '1fr 2fr',
          xl: '1fr 3fr',
        }}
        gap={{ md: 4, lg: 8 }}
      >
        <Box height="100%">
          <LibraryFolders
            mt={6}
            folders={folders}
            isLoading={isLoadingFolders}
            onSelect={setCurrentFolder}
            onEdit={folderCRUDIntents.editFolder}
            onDelete={folderCRUDIntents.deleteFolder}
            selectedFolderUid={folderUid}
          />
        </Box>
        <Box>
          <React.Suspense fallback={<Spinner display="block" m="5rem auto" />}>
            <LibraryFolderContents uid={folderUid} />
          </React.Suspense>
        </Box>
      </Grid>
    </Box>
  )
}

export default function LibraryPageWrapper(): React.ReactElement {
  return (
    <AudioProvider multiSource={false}>
      <LibraryPage />
    </AudioProvider>
  )
}
