import { formatTime } from '@capturi/audio'
import { useCurrentUser } from '@capturi/core'
import { useSingleUser } from '@capturi/stores'
import {
  ListItemMenu,
  MenuItem,
  MenuList,
  useToast,
} from '@capturi/ui-components'
import { Box, Flex, Icon, MenuDivider, Text } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { PlayListItem } from 'components/PlayList'
import { LibraryAnalyticsEvent, LibraryItem, logEvent } from 'features/library'
import React from 'react'
import {
  MdContentCopy,
  MdDelete,
  MdEdit,
  MdFileDownload,
  MdLaunch,
} from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'

import routes from '../routes'

type SnippetProps = LibraryItem & {
  isFocused: boolean
  onPlay: () => void
  onPause: () => void
  onGoToConversation: () => void
  onDelete: () => void
  onEdit: () => void
  onDownload: () => void
}

const Snippet: React.FC<SnippetProps> = ({
  uid,
  title,
  createdOn,
  description,
  createdByUserUid,
  from,
  to,
  isFocused,
  conversationDateTime,
  onPlay,
  onPause,
  onGoToConversation,
  onDelete,
  onEdit,
  onDownload,
}) => {
  const currentUser = useCurrentUser()

  const user = useSingleUser(createdByUserUid)
  const toast = useToast()
  const [, copy] = useCopyToClipboard()
  const duration = to - from
  const conversationDate = conversationDateTime

  return (
    <PlayListItem
      context={{ snippetUid: uid }}
      isLibrarySnippet
      onPlay={onPlay}
      onStop={onPause}
      isSelected={isFocused}
      showAudioPlayer
    >
      <Box>
        <Box mb="1">
          <Flex gap="2" align="baseline">
            <Text fontWeight="medium" fontSize="lg" lineHeight="1">
              {title}
            </Text>
            <Text fontSize="md" color="textMuted" lineHeight="1">
              {formatTime(duration)}
            </Text>
          </Flex>
          <Text fontSize="md">{description}</Text>
        </Box>
        <Box>
          <Text fontSize="sm" color="textMuted">
            <Trans>Conversation date: {i18n.date(conversationDate)}</Trans>
          </Text>
          <Text fontSize="sm" color="textMuted">
            <Trans>
              Created: {i18n.date(createdOn)} by {user.name}
            </Trans>
          </Text>
        </Box>
      </Box>
      <ListItemMenu placement={'auto-start'}>
        <MenuList>
          <MenuItem onClick={onGoToConversation}>
            <Icon as={MdLaunch} />
            <Text ml="2">
              <Trans>Go to conversation</Trans>
            </Text>
          </MenuItem>
          <MenuItem
            onClick={(): void => {
              copy(window.location.origin + routes.audioSnippet(uid))
              toast({
                title: t`Copied to clipboard`,
              })
              logEvent(LibraryAnalyticsEvent.CopySnippetLink)
            }}
          >
            <MdContentCopy />
            <Text ml="2">
              <Trans>Copy link</Trans>
            </Text>
          </MenuItem>
          {currentUser.isAdminOrTeamLead &&
            currentUser.permissions.playback &&
            currentUser.permissions.download && (
              <MenuItem onClick={onDownload}>
                <MdFileDownload />
                <Text ml="2">
                  <Trans>Download</Trans>
                </Text>
              </MenuItem>
            )}
          {currentUser.permissions.editLibrary && (
            <>
              <MenuDivider />
              <MenuItem onClick={onEdit}>
                <MdEdit />
                <Text ml="2">
                  <Trans>Edit</Trans>
                </Text>
              </MenuItem>
              <MenuItem onClick={onDelete}>
                <MdDelete />
                <Text ml="2">
                  <Trans>Delete</Trans>
                </Text>
              </MenuItem>
            </>
          )}
        </MenuList>
      </ListItemMenu>
    </PlayListItem>
  )
}

export default Snippet
