import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'

import { Button } from '../Button'

type Props = {
  title: string | ReactElement
  message: string | ReactElement
  confirmText: string | ReactElement
  cancelText: string | ReactElement
  onConfirm: () => void
  onClose: () => void
  inProgress: boolean
  errorMessage?: string
}

const DeleteDialog: React.FC<Props> = ({
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onClose,
  inProgress,
  errorMessage,
}) => {
  const cancelRef = React.useRef(null)

  return (
    <>
      <AlertDialog isOpen leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>

          <AlertDialogBody>{message}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} isDisabled={inProgress}>
              {cancelText}
            </Button>
            <Button
              colorScheme="red"
              onClick={onConfirm}
              ml={3}
              isLoading={inProgress}
            >
              {confirmText}
            </Button>
          </AlertDialogFooter>
          {errorMessage && (
            <Text textAlign="end" color="danger" mr="6" mb="6">
              {errorMessage}
            </Text>
          )}
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default DeleteDialog
