import { useModal } from '@capturi/use-modal'
import { t } from '@lingui/macro'
import CancelActionModal from 'components/CancelActionModal'
import { useAudioSnippets } from 'features/library'
import useAudioSnippetCRUD from 'features/library/useSnippetCRUD'

function useCancelSnippetDeletionModal(folderUid: string): () => void {
  const [showCancelActionModal] = useModal(CancelActionModal)

  const librarySnippets = useAudioSnippets(folderUid)
  const { deleteAllSnippets } = useAudioSnippetCRUD({
    onSnippetsDeleted: () => {
      librarySnippets.mutate(
        (data) =>
          data && {
            audioSnippets: [],
          },
      )
    },
  })

  function handleTimerModal(): void {
    showCancelActionModal({
      action: () => deleteAllSnippets(librarySnippets.snippets),
      label: t`Deleting all snippets...`,
      description: t`Snippets can't be retrieved again after deletion`,
    })
  }

  return handleTimerModal
}

export default useCancelSnippetDeletionModal
