import { SWRConfiguration, SWRResponse, mutate } from 'swr'

import { api as apiFactory, useAPI } from './api'
import { LibraryFolder } from './types'

export const mutateLibraryFolder = (
  uid: string,
  folder: LibraryFolder,
  revalidate?: boolean,
): Promise<LibraryFolder | undefined> => {
  return mutate(apiFactory().getFolder(uid)[0], folder, revalidate)
}

function useLibraryFolder(
  folderUid?: string,
  swrConfig: SWRConfiguration = {},
): {
  folder?: LibraryFolder
  isLoading: boolean
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  mutate: SWRResponse<LibraryFolder, any>['mutate']
} {
  const { data, isValidating, mutate } = useAPI<LibraryFolder>(
    (api) => (folderUid ? api.getFolder(folderUid) : null),
    {
      suspense: true,
      revalidateOnFocus: false,
      ...swrConfig,
    },
  )
  return {
    folder: data,
    isLoading: isValidating,
    mutate,
  }
}

export default useLibraryFolder
