import { LibraryFolder, LibraryFolderResponseModel } from '../types'

type MaybeLibraryFolders = LibraryFolderResponseModel | undefined

export const addFolder = (
  d: MaybeLibraryFolders,
  folder: LibraryFolder,
): MaybeLibraryFolders => {
  return d
    ? {
        folders: d.folders.concat(folder),
      }
    : undefined
}

export const deleteFolder = (
  d: MaybeLibraryFolders,
  uid: string,
): MaybeLibraryFolders => {
  return d
    ? {
        folders: d.folders.filter((x) => x.uid !== uid),
      }
    : undefined
}

export const replaceFolder = (
  d: MaybeLibraryFolders,
  folder: LibraryFolder,
): MaybeLibraryFolders => {
  return d
    ? {
        folders: d.folders.map((x) => (x.uid === folder.uid ? folder : x)),
      }
    : undefined
}
