import { useOrganization } from '@capturi/stores'
import { useMemo } from 'react'
import { SWRConfiguration, SWRResponse } from 'swr'

import { useAPI } from './api'
import { LibraryItem, SnippetLibraryResponseModel } from './types'

export type UseAudioSnippetsSortCriteria =
  | 'alphabetically'
  | 'conversationDate'
  | 'creationDate'

export function useAudioSnippets(
  folderUid?: string,
  sortCriteria: UseAudioSnippetsSortCriteria = 'creationDate',
  swrConfig: SWRConfiguration = {},
): {
  snippets: LibraryItem[]
  isLoading: boolean
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  mutate: SWRResponse<SnippetLibraryResponseModel, any>['mutate']
} {
  const { data, mutate } = useAPI<SnippetLibraryResponseModel>(
    (api) => (folderUid ? api.getSnippets() : null),
    () => ({
      folderUid: folderUid,
      pageSize: 999,
      pageNumber: 0,
    }),
    {
      suspense: true,
      revalidateOnFocus: false,
      dedupingInterval: 10000,
      ...swrConfig,
    },
  )

  const { conversationLanguage } = useOrganization()
  const compare = Intl.Collator(conversationLanguage).compare

  return useMemo(() => {
    return {
      snippets: (data?.audioSnippets ?? []).sort((a, b) => {
        switch (sortCriteria) {
          case 'alphabetically':
            return compare(a.title, b.title)
          case 'conversationDate':
            return (
              b.conversationDateTime.getTime() -
              a.conversationDateTime.getTime()
            )
          default:
            return b.createdOn.getTime() - a.createdOn.getTime()
        }
      }),
      isLoading: !data,
      mutate,
    }
  }, [compare, data, mutate, sortCriteria])
}
