import { DeleteDialog } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Trans } from '@lingui/macro'
import React from 'react'

type Props = BaseModalComponentProps & {
  next: () => void
}

export function LibraryFolderSnippetsDeleteModal({
  onClose,
  isOpen,
  next,
}: Props): React.ReactElement {
  return (
    <>
      {isOpen && (
        <DeleteDialog
          title={
            <Trans>
              Are you sure you wish to delete all content in the folder?
            </Trans>
          }
          message={
            <Trans>
              After deletion, you will no longer be able to retrieve any of the
              snippets.
            </Trans>
          }
          onConfirm={() => {
            onClose()
            next()
          }}
          confirmText={<Trans>Delete snippets</Trans>}
          cancelText={<Trans>Cancel</Trans>}
          onClose={onClose}
          inProgress={false}
        />
      )}
    </>
  )
}
